import React from 'react'
import T from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Hero from '../components/Hero'
import ImageList from '../components/ImageList'
import { Container } from '@components'

export default function Page({
  data: { coverImage, chefs, chef, behind, food1, food2, food3 },
}) {
  const RECIPES = [
    {
      id: '1',
      ...food1,
    },
    {
      id: '2',
      ...food2,
    },
    {
      id: '3',
      ...food3,
    },
  ]
  return (
    <>
      <SEO title="Om Foodsteps" />
      <Hero
        title="Vi vil gjøre det lett å lage god mat hjemme"
        image={coverImage.image}
        type="small"
        aspectRatio={3 / 4}
      />
      <Hero
        title="Drives av fagfolk"
        preamble="Foodsteps springer ut fra Kulinarisk Akademi (KA), et kompetansesenter for mat og drikke. Der jobber noen av landets dyktigste kokker, vinkelnere og matentusiaster, som alle brenner for å dele kunnskap og matglede. "
        image={chefs.image}
        type={['uppercase']}
        aspectRatio={3 / 4}
        section
      />
      <Hero
        title="Vi kan matformidling"
        preamble="Vi har drevet med matformidling siden 2008, og holder hvert år matkurs for 12 000-15 000 personer. I tillegg har vi gitt ut tre kokebøker."
        image={chef.image}
        type={['uppercase']}
        aspectRatio={3 / 4}
        section
      />
      <Hero
        title="Digital kunnskap"
        preamble="For å dekke den økende etterspørselen etter kurs og matkunnskap etablerer vi den digitale tjenesten Foodsteps, med matkurs og oppskrifter spesiallaget for små skjermer."
        image={behind.image}
        type={['uppercase']}
        aspectRatio={3 / 4}
        section
      />

      <Hero
        title="Under utvikling"
        preamble={
          <>
            Tjenesten er under utvikling og det vil stadig komme nye kurs,
            oppskrifter og ukesmenyer, samt mulighet for å kjøpe gavekort privat
            eller som bedrift. Send oss ønsker og forslag på{' '}
            <a
              href="mailto:post@foodsteps.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              post@foodsteps.no
            </a>
            .
          </>
        }
        type={['small-image', 'uppercase']}
        aspectRatio={9 / 16}
        section
      />
      <Container>
        <ImageList items={RECIPES} type="3" />
      </Container>
    </>
  )
}

Page.propTypes = {
  data: T.object.isRequired,
}

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "stokkoya.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chefs: file(relativePath: { eq: "karoline.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chef: file(relativePath: { eq: "ka-kokk.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    behind: file(relativePath: { eq: "behind-the-scenes.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # DINNERS
    food1: file(relativePath: { eq: "nuddel-biff.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food2: file(relativePath: { eq: "oppskrifter/matpakkebrod.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food3: file(relativePath: { eq: "oppskrifter/kjottsaus.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
